import React from "react"
import Layout from "../components/layout";
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import EmailSubscribe from "../components/email-subscribe";

const AuthorPages = ({ data: { author }}) => {

  const displayName = author.name
  const bio = author.description
  const gravatar = author.avatar.url
  const posts = author.posts.nodes
  const seoTitle = author.seo.title
  const seoMeta = author.seo.metaDesc
  const twitterHandle = author.seo.social.twitter
  const linkedInUrl = author.seo.social.linkedIn


  console.log(gravatar)

  return (

    <Layout
      title={seoTitle}
      description={seoMeta}
    >
      <div id="bio" className="mt-40 mb-10 sm:mb-40">
        <div className="max-w-2xl text-center mx-auto">
          <h1 className="text-4xl">{displayName}</h1>
          <p>{bio}</p>
          <div id="social-container" className="flex flex-row justify-center space-x-8">
            <a className="text-slate-500" href={`https://www.twitter.com/${twitterHandle}`}>Twitter</a>
            <a className="text-slate-500" href={linkedInUrl}>LinkedIn</a>
          </div>
        </div>
      </div>

      <div id="latest-articles" className="my-20 max-w-4xl mx-auto">
        <h2 className='text-4xl text-center'>Latest stories</h2>
        {posts.map((post) => {
          return (
            <div id="parent-JSX-div" className='px-8 sm:px-0'>
              <div id="top-dividing-line" className='border-t border-slate-200 my-8'></div>
              <div id="content-cards" className="flex flex-col-reverse sm:items-center sm:flex-row">
                <Link to={post.uri} className='sm:max-w-xs'>
                  <GatsbyImage 
                    image={getImage(post.featuredImage.node.localFile.childImageSharp.gatsbyImageData)}
                  />
                </Link>
                <Link 
                  to={post.uri}
                  className="text-3xl font-extrabold text-slate-800 no-underline mb-4 sm:pl-4" 
                >
                  {post.title}
                </Link>
              </div>
            </div>
          )
        })}

      </div>

    </Layout>

  )
}

export default AuthorPages

export const pageQuery = graphql`
  query GetByAuthorSlug($id: String!) {
    author: wpUser(id: {eq: $id}) {
      id
      uri
      name
      description
      avatar {
        url
      }
      seo {
        title
        metaDesc
        social {
          twitter
          linkedIn
        }
      }
      posts {
        nodes {
          id
          title
          uri
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`